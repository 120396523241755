/* eslint-disable */
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import ReactCrop, { centerCrop, Crop, makeAspectCrop, PixelCrop } from "react-image-crop";
import { useNavigate, useParams } from "react-router";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";

import { useMutation } from "@apollo/client";
import { ArrowBackIos, RotateLeft, RotateRight } from "@material-ui/icons";
import { CircularProgress } from "@mui/material";
import apiFn from "asset/apiClass";
import "react-image-crop/dist/ReactCrop.css";
import Resizer from "react-image-file-resizer";
import { AskImageQuestionDocument } from "types/graphql/graphql";
import UiLoading from "uiComponents/UiLoading/UiLoading";
import constants from "../../asset/constants";
import { CommonContext, PaymentContext } from "../../asset/context";
import { getUser } from "../../asset/storage";
import store from "../../asset/store";
import style from "../../asset/style";
import util from "../../asset/util";
import useQueryString from "../../hooks/useQueryString";
import "./UiCrop.css";
// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  // // console.log(mediaWidth)
  // // console.log(mediaHeight)
  // // console.log(aspect)
  return centerCrop(
    makeAspectCrop(
      {
        unit: "px", // 0913 project 5 관련 수정 % -> px
        width: mediaWidth / 2, // 0913 project 5 관련 수정 mediaWidth -> mediaWidth/2
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export default function App({ setFileObj, fileObj, qnaData, setIsCrop, addSearch }) {
  let params = useParams();
  let navigate = useNavigate();
  const { getQueryString } = useQueryString();
  const userInfo = getUser();

  const [isLoading, setImageLoading] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const [isPortrait, setIsPortrait] = useState(false);
  const [windowSize, setWindowSize] = useState({});

  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState<number | undefined>(1);

  const [img_obj, setImg_obj] = useState({});
  const { setFileObj: saveImageData } = useContext(CommonContext);
  const [isPending, setIsPending] = useState(false);

  const classroomId = constants.classroomId;

  // 결제 질문하기 관련
  const hostname = window.location.hostname;
  let channel = constants.channelId ?? "default";

  const [askImageQuestion] = useMutation(AskImageQuestionDocument, {
    errorPolicy: "all",
  });
  const {
    setPaymentModalOpen,
    academyId,
    pickResult,
    setPickResult,
    selectedLectureId,
    selectedTeacherId,
    setSelectedLectureId,
    setSelectedTeacherId,
    paymentOpen,
    setAcademyId,
    defaultAcademyId,
  } = useContext(PaymentContext);

  const selectClassWithDefaultBook = async (_fileObj) => {
    let book = null;
    try {
      const response = await fetch(`${constants.apiUrl}/class/${selectedLectureId}/default`);

      if (response.status !== 200) {
        throw new Error("no default");
      }

      book = await response.json();
    } catch (e) {
      alert("수업 기본 교재가 없습니다. 선생님께 문의해주세요.");
      throw new Error("Default Not Found");
    }
    if (!book) {
      throw new Error("no book");
    }
    if (isPending) return;
    try {
      studentAskQuestion(
        {
          bid: parseInt(book.bid),
          tid: selectedTeacherId,
          sid: userInfo["id"],
          channel,
          ticketType: pickResult,
        },
        _fileObj
      );
    } catch (e) {
      console.error(e);
      alert("문제 등록 중 오류가 발생했습니다");
    }
  };
  const studentAskQuestion = async ({ bid, sid, tid, channel, ticketType }, _fileObj) => {
    try {
      let bucketImgPaths = [];
      setIsPending(true);
      // 1. 모든 이미지를 버킷에 한 번에 등록한다.
      try {
        if (_fileObj?.length > 1) {
          bucketImgPaths = await Promise.all(
            Array.from(_fileObj).map((imgFile: any) =>
              apiFn.uploadFile(`qna/temp-${sid}/${imgFile.name}`, imgFile)
            )
          );
        } else {
          let questionFile = util.dataURLtoFile(_fileObj["base64"]);
          let imgPath = `qna/temp-${sid}/-${new Date().getMilliseconds()}.jpg`;
          imgPath = await apiFn.uploadFile(imgPath, questionFile);
          bucketImgPaths.push(imgPath);
        }
      } catch (e) {
        console.error(e);
        throw new Error("이미지를 추가하는 도중 오류가 발생했습니다.");
      }
      // 2. askImageQuestion[GraphQL]를 호출한다.
      let variables;
      if (paymentOpen !== "none") {
        variables = {
          input: {
            bookId: bid,
            teacherId: tid,
            studentId: sid,
            images: bucketImgPaths,
            channel,
            academyId,
            ticketType,
          },
        };
      } else {
        variables = {
          input: {
            bookId: bid,
            teacherId: tid,
            studentId: sid,
            images: bucketImgPaths,
            channel,
            academyId,
          },
        };
      }

      let { errors, data } = await askImageQuestion({ variables });
      if (!data?.askImageQuestion) {
        throw new Error(errors?.[0]?.message);
      }
      setFileObj({});
      const qid = data.askImageQuestion.id;
      cleanUpPayment();
      navigate(`/talk/student/0/${qid}/${sid}/${tid}`, { replace: true });
    } catch (e: any) {
      console.error(e);
      alert(e?.message || "등록하는 도중 오류가 발생했습니다. 사진을 다시 한번 확인해주세요.");
      cleanUpPayment();
      navigate(`/message/student/home`, { replace: true });
    } finally {
      setIsPending(false);
    }
  };

  const cleanUpPayment = () => {
    console.log("cleanUpPayment 실행");
    setPickResult(false);
    setPaymentModalOpen(false);
    setSelectedLectureId(null);
    setSelectedTeacherId("");
    setAcademyId(defaultAcademyId);
  };

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      // console.log("### ",reader.result.toString());
      reader.addEventListener("load", () => setImgSrc(reader.result.toString() || ""));
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;

      setAspect(0);
      // // console.log("image Load A => ",width)
      // // console.log("image Load B => ",height)
      // // console.log("image Load C => ",width/height)
      // // console.log("image Load D => ",centerAspectCrop(width, height, width/height))
      setImg_obj({
        width: width,
        height: height,
        w_h: width / height,
      });

      // 0913 projcet 5 이미지 초기화 크롭 관련 주석
      //   setCrop(centerAspectCrop(width, height, width/height))
      //   let pixel_obj:PixelCrop = {
      //     unit : "px",
      //     x : 1,
      //     y : 1,
      //     // width : 10000,
      //     // height : 10000,
      //     width : imgRef.current.width/2,
      //     height : imgRef.current.height,
      //     // width : qq["width"],
      //     // height : qq["height"],
      //   }

      //   setCompletedCrop(pixel_obj)
      ////////////////////////////////////////////////

      // 0913 projcet 5 이미지 초기화 크롭 관련 처리
      let pixel_obj: PixelCrop = {
        unit: "px",
        x: centerAspectCrop(width, height, width / height).x,
        y: centerAspectCrop(width, height, width / height).y,
        // width : 10000,
        // height : 10000,
        width: centerAspectCrop(width, height, width / height).width,
        height: centerAspectCrop(width, height, width / height).height,
        // width : qq["width"],
        // height : qq["height"],
      };

      setCompletedCrop(pixel_obj);
      setAspect(0);
      setCrop(centerAspectCrop(width, height, aspect));
      /////////////////////////////////

      // console.log("centerAspectCrop => ",centerAspectCrop(width, height, width/height))
      //   if(params["type"] == "se" || params["type"] == "rectangle" || params["type"] == "set"){
      //     // // console.log("$$$$ ## #  # # # ############## # # #")
      //     // // console.log("width => ",width)
      //     // // console.log("height => ",height)
      //     setAspect(0)
      //     setCrop(centerAspectCrop(width, height, width/height))
      //     // setCompletedCrop(centerAspectCrop(width, height, width/height))
      //   }
      //   else {
      //     // // console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$")
      //     setCrop(centerAspectCrop(width, height, aspect))
      //     // setCompletedCrop(centerAspectCrop(width, height, width/height))
      //   }
    }
  }

  const getCrop = (
    image: HTMLImageElement,
    canvas: HTMLCanvasElement,
    crop: PixelCrop,
    scale = 1,
    rotate = 0
  ) =>
    new Promise((r, j) => {
      try {
        // // console.log("!")
        // // console.log("(in) crop => ",crop)
        // alert(image)
        // alert(canvas)
        // alert(crop)
        // alert(JSON.stringify(image));

        const TO_RADIANS = Math.PI / 180;
        const ctx = canvas.getContext("2d");

        if (!ctx) {
          throw new Error("No 2d context");
        }

        // const scaleX = 1
        // const scaleY = 1

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        // alert(image.naturalWidth)
        // alert(image.naturalHeight)
        // devicePixelRatio slightly increases sharpness on retina devices
        // at the expense of slightly slower render times and needing to
        // size the image back down if you want to download/upload and be
        // true to the images natural size.

        const pixelRatio = 1;

        // const pixelRatio = window.devicePixelRatio

        // // console.log("window.devicePixelRatio => ",window.devicePixelRatio)

        // // console.log("scaleX  => ",scaleX )
        // // console.log("scaleY  => ",scaleY )
        // const pixelRatio = 1

        // alert(crop.height)
        // alert(crop.width)

        canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
        canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

        // // console.log("(cla) canvas.width => ",canvas.width)
        // // console.log("(cla) canvas.height => ",canvas.height)

        ctx.scale(pixelRatio, pixelRatio);
        ctx.imageSmoothingQuality = "high";

        const cropX = crop.x * scaleX;
        const cropY = crop.y * scaleY;

        // alert(crop.x)
        // alert(crop.y)

        const rotateRads = rotate * TO_RADIANS;

        // alert(rotateRads)
        const centerX = image.naturalWidth / 2;
        const centerY = image.naturalHeight / 2;

        ctx.save();

        // 5) Move the crop origin to the canvas origin (0,0)
        ctx.translate(-cropX, -cropY);
        // 4) Move the origin to the center of the original position
        ctx.translate(centerX, centerY);
        // 3) Rotate around the origin
        ctx.rotate(rotateRads);
        // 2) Scale the image
        ctx.scale(scale, scale);
        // 1) Move the center of the image to the origin (0,0)
        ctx.translate(-centerX, -centerY);

        ctx.drawImage(
          image,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight
        );

        // alert(canvas)

        canvas.toBlob(
          (b) => {
            try {
              // // console.log(b)
              // alert("#############");
              // alert(b);
              Resizer.imageFileResizer(
                b,
                1280,
                1280,
                "JPEG",
                90,
                0,
                (uri) => {
                  // // console.log("@@@ ",uri)
                  r(uri);
                  ctx.restore();
                },
                "base64"
              );
            } catch (err) {
              // console.log("EROR => ",err)
              // alert("####")
              // alert(err);
              setImageLoading(false);
            }
          },
          "image/jpeg",
          1.0
        );
      } catch (e) {
        setImageLoading(false);
        // console.log("get Crop Error => ",e)
      }
    });

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // // console.log(  imgRef.current)
        // // console.log(previewCanvasRef.current)
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate);
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined);
    } else if (imgRef.current) {
      const { width, height } = imgRef.current;
      setAspect(1);
      setCrop(centerAspectCrop(width, height, 16 / 9));
    }
  }

  // S3 저장
  const apiPro_img = async (e) => {
    try {
      // let reader = new FileReader();
      // let file = e.target.files[0];
      // reader.readAsDataURL(file)

      previewCanvasRef.current.width = completedCrop.width;
      previewCanvasRef.current.height = completedCrop.height;

      const name = `${fileObj["name"]}`;
      let _img = await getCrop(
        imgRef.current,
        previewCanvasRef.current,
        completedCrop,
        scale,
        rotate
      );
      let isRe = false;
      let isMsgImg = false;
      if (fileObj["isRe"]) {
        isRe = true;
      }
      if (fileObj["isMsgImg"]) {
        isMsgImg = true;
      }

      // const result =  await store.putBucket(_img, name, false, isRe, false, false, isMsgImg);
      // // console.log(result);
      // alert("this. 3",result)

      setFileObj({
        ...fileObj,
        base64: _img,
      });
      // setIsCrop(false)
      setImageLoading(false);

      const stack = store.get("stack");
      if (stack.length > 0) {
        if (getQueryString("myInfo") === "true") {
          try {
            await apiFn.uploadFile(
              `${params["who"]}/${userInfo.id}` + ".jpg",
              util.dataURLtoFile(_img)
            );
            setFileObj({});
          } catch (e) {
            console.error(e);
          }
        }
        navigate(-1);
      } else navigate("/");
    } catch (e) {
      setImageLoading(false);
      // alert("error => ")
      // alert(e)
      // alert(JSON.stringify(e))
      // console.log("apiPro_img Error= >",e)
    }
  };

  // img set
  const set_img = async (e) => {
    let _fileObj;
    try {
      const name = `${fileObj["name"]}`;
      // // console.log("$$ ",imgRef.current)
      // // console.log("$$ ",previewCanvasRef.current)
      // // console.log("$$ ",completedCrop)

      let _img = await getCrop(
        imgRef.current,
        previewCanvasRef.current,
        completedCrop,
        scale,
        rotate
      );
      setFileObj({
        ...fileObj,
        isImg: true,
        base64: _img,
      });
      _fileObj = { ...fileObj, isImg: true, base64: _img };
      if (setIsCrop) {
        setIsCrop(false);
      } else {
        if (params["type"] == "set") {
          const stack = store.get("stack");
          if (stack.length > 0) navigate(-1);
          else navigate("/");
        } else if (params["type"] == "se" || params["type"] == "qnaRegi") {
          if (qnaData["tid"] || qnaData["bid"]) {
            if (qnaData["tid"]) {
              if (!classroomId && paymentOpen === "select") {
                // 결제 질문하기 함수 호출
                selectClassWithDefaultBook(_fileObj);
                return <UiLoading text="질문을 등록 중입니다." />;
              } else {
                navigate(`/searchresult/student/${params["who"]}/b`); // 교재 선택 화면
              }

              // navigate(`/searchresult/student/${params["who"]}/l`) // 수업 선택 화면
            } else {
              navigate(`/searchresult/student/${params["who"]}/t`);
            }
          } else {
            navigate("/searchresult/" + params["who"] + "/de/de");
          }
          // navigate("/searchresult/"+params["who"]+"/de/de")
        }
        setImageLoading(false);
      }
    } catch (e) {
      setImageLoading(false);
      // console.log("apiPro_img Error= >",e)
    }
  };

  const handleSearchImage = useCallback(async () => {
    const cropImageBase64 = await getCrop(
      imgRef.current,
      previewCanvasRef.current,
      completedCrop,
      scale,
      rotate
    );
    saveImageData({ ...fileObj, base64: cropImageBase64 });
    if (params.type === "bookImageSearch") {
      navigate(`/search/question/book/${addSearch?.bid}/image`);
    } else navigate("/search/question/image");
  }, [completedCrop]);

  useEffect(() => {
    // // console.log("completedCrop => ",completedCrop);
    // // console.log("previewCanvasRef => ",previewCanvasRef);
    // if(previewCanvasRef["current"]){
    //   setTimeout(() => {
    //     // console.log("previewCanvasRef => ",previewCanvasRef["current"].toDataURL());
    //   }, (5000));
    // }
  }, [completedCrop]);

  useEffect(() => {
    // // console.log("imgSrc => ",imgSrc);
  }, [imgSrc]);

  useEffect(() => {
    // // console.log("windowSize => ",windowSize);
    if (windowSize["width"]) {
      if (windowSize["width"] < windowSize["height"]) {
        setIsPortrait(true);
        // alert("por"+JSON.stringify(windowSize))
      } else {
        // alert("no por"+JSON.stringify(windowSize))
        setIsPortrait(false);
      }
      setCrop(centerAspectCrop(img_obj["width"], img_obj["height"], img_obj["w_h"]));
      // // console.log("*******************************************")
      // // console.log(imgRef.current.width)
      // // console.log(imgRef.current.height)
      // // console.log(centerAspectCrop(
      //   img_obj["width"],
      //   img_obj["height"],
      //   img_obj["w_h"]
      // ))
      // let qq = centerAspectCrop_px(
      //   img_obj["width"],
      //   img_obj["height"],
      //   img_obj["w_h"]
      // )

      let pixel_obj: PixelCrop = {
        unit: "px",
        x: 1,
        y: 1,
        // width : 10000,
        // height : 10000,
        width: imgRef.current.width,
        height: imgRef.current.height,
        // width : qq["width"],
        // height : qq["height"],
      };

      // // console.log("pixel_obj =. ",pixel_obj);

      setCompletedCrop(pixel_obj);
      // // console.log("*******************************************")
    } else {
      // alert("???")
      setIsPortrait(true);
    }
  }, [windowSize]);

  // useEffect(()=>{
  //   // console.log("********************** ",crop)
  // },[crop])

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    try {
      localStorage.removeItem("ocrData");
      if (fileObj["file"]) {
        let reader = new FileReader();
        let file = fileObj["file"].target.files[0];
        // reader.readAsDataURL(file)
        setFileObj({
          ...fileObj,
          fileName: file["name"],
        });
        onSelectFile(fileObj["file"]);

        window.addEventListener("resize", handleResize);
        handleResize();
        return () => {
          // cleanup
          window.removeEventListener("resize", handleResize);
        };
      } else if (Object.keys(fileObj).length === 0) {
        const stack = store.get("stack");
        if (stack.length > 0) navigate(-1);
        else navigate("/");
      }
    } catch (error) {
      console.error("Error => ", error);
    }
  }, []);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "black",
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          position: "absolute",
          top: 0,
          padding: "15px 15px",
          // backgroundColor:"red",
        }}
      >
        <ArrowBackIos
          style={{ color: "white" }}
          onClick={() => {
            if (setIsCrop) {
              setFileObj({});
              setIsCrop(false);
            } else {
              const stack = store.get("stack");
              if (stack.length > 0) navigate(-1);
              else navigate("/");
            }
          }}
        />
        <div
          style={{
            fontSize: "18px",
            fontWeight: "bold",
            color: "white",
          }}
        >
          {params["type"] == "se"
            ? "사진으로 해답 찾기"
            : params["type"] == "qnaRegi"
              ? "문제 등록하기"
              : "이미지 편집"}
        </div>
      </div>
      {params["type"] == "set" ? (
        <></>
      ) : params["type"] == "se" || params["type"] == "qnaRegi" ? (
        <div
          style={{
            fontSize: "12px",
            fontWeight: "bold",
            color: "rgba(255,255,255,0.8)",
            // backgroundColor:'orange',
            display: "flex",
            justifyContent: "center",
            flex: 1,
            width: "100%",
            // marginRight:"30px",
            position: "absolute",
            top: 100,
            zIndex: 99999999999999999,
            left: 0,
            padding: "10px 0px",
          }}
        >
          문항을 정방향으로 회전하여 한문제만 영역설정해 주세요.
        </div>
      ) : (
        <></>
      )}
      {/* <div>
            <input
                type="file"
                accept="image/*"
                onChange={onSelectFile}
                style={{
                    position : "absolute",
                    zIndex:99,
                }}
            />
        </div> */}
      <div
        style={{
          width: "100vw",
          // backgroundColor:"yellow",
          height: isPortrait ? "80vh" : "100vh",
          display: "flex",
          marginTop: isPortrait ? "70px" : "0px",
          alignItems: "center",
          justifyContent: isPortrait ? "center" : "space-between",
          // backgroundColor:"red",
        }}
      >
        {/* <div style={{
                height : '60vh',
                width:"100%",
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                // backgroundColor:"green"
            }}> */}

        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            // backgroundColor:"orange",
          }}
        >
          <ReactCrop
            style={{
              // backgroundColor:"green",
              maxHeight: "80vh",
              // width:"auto",
              // width: "98vw" ,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: isPortrait ? "0px" : "70px",
            }}
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => {
              // // console.log("COMPLETE => ",c);
              setCompletedCrop(c);
            }}
            aspect={aspect}
          >
            <img
              ref={imgRef}
              alt="Crop me"
              src={imgSrc}
              style={{
                // display:"flex",
                // flex:1,
                // width:"100%",
                // maxHeight: '80vh' ,
                // width:'100vw',
                // zIndex:102,
                // height:"50vh",
                // width:isPortrait ?  "100%" : "calc (100% - 70px)",
                // objectFit:"cover",
                transform: `scale(${scale}) rotate(${rotate}deg)`,
                // transform: `rotate(${rotate}deg)`
              }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        </div>

        {/* </div> */}
        <div
          style={{
            // position : "absolute",
            // right:0,
            // bottom : 0,
            height: "100vh",
            backgroundColor: "white",
            // zIndex:999999999999999999999999999999999999999999999,
            width: "70px",
            display: isPortrait ? "none" : "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              flexDirection: "column",
              alignItems: "center",
              width: "92%",
              height: "100%",
            }}
          >
            <RotateLeft
              style={{
                fontSize: "48px",
              }}
              onClick={() => {
                // // console.log(Math.min(360, Math.max(-360, Number(rotate-90))))
                // // console.log("scale => ",scale);
                let _rotate = Math.min(360, Math.max(-360, Number(rotate - 90)));
                if (Math.abs(_rotate) == 90 || Math.abs(_rotate) == 270) {
                  setScale(0.65);
                } else {
                  setScale(1);
                }
                if (_rotate == 360 || _rotate == -360) {
                  setRotate(0);
                } else {
                  setRotate(Math.min(360, Math.max(-360, Number(rotate - 90))));
                }
              }}
            />
            <button
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid",
                borderColor: style.common.borderColor,
                fontSize: 14,
                height: "44px",
                cursor: "pointer",
                outline: "none",
                margin: "7.5px 0px",
                color: "white",
                width: "60px",
                backgroundColor: style.common.t_color,
                borderRadius: 20,
              }}
              onClick={() => {
                if (setIsCrop) {
                  apiPro_img(null);
                } else {
                  setImageLoading(true);
                  if (params["type"] == "d" || params["type"] == "rectangle") {
                    apiPro_img(null);
                  } else if (
                    params["type"] == "set" ||
                    params["type"] == "se" ||
                    params["type"] == "qnaRegi"
                  ) {
                    set_img(null);
                  } else if (params.type === "searchImage" || params.type === "bookImageSearch") {
                    handleSearchImage();
                  }
                }
              }}
            >
              {params["type"] == "se" ? "검색" : params["type"] == "qnaRegi" ? "등록" : "확인"}
            </button>
            {/* <UiBtn
                        title={params["type"] == "se" ? "검색" : "확인" }
                        type={"small"}
                        btnStyle={{
                            width : "100px",
                            height:"40px",
                            backgroundColor : style.common.t_color,
                            color : "white",
                            borderRadius : 20,
                        }}
                        fnClick={()=>{
                            if(params["type"] == "d"){
                            apiPro_img();
                            }
                            else if(params["type"] == "set" || params["type"] == "se" ) {
                            set_img();
                            }
                        }}
                    /> */}
            <RotateRight
              style={{
                fontSize: "48px",
              }}
              onClick={() => {
                // setRotate(Math.min(180, Math.max(-180, Number(rotate+90))))
                let _rotate = Math.min(360, Math.max(-360, Number(rotate + 90)));
                if (Math.abs(_rotate) == 90 || Math.abs(_rotate) == 270) {
                  setScale(0.65);
                } else {
                  setScale(1);
                }
                if (_rotate == 360 || _rotate == -360) {
                  setRotate(0);
                } else {
                  setRotate(Math.min(360, Math.max(-360, Number(rotate + 90))));
                }
              }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: 0,
          height: "70px",
          backgroundColor: "white",
          width: "100vw",
          display: isPortrait ? "flex" : "none",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "92%",
          }}
        >
          <RotateLeft
            style={{
              fontSize: "48px",
            }}
            onClick={() => {
              // // console.log(Math.min(360, Math.max(-360, Number(rotate-90))))
              // // console.log("scale => ",scale);
              let _rotate = Math.min(360, Math.max(-360, Number(rotate - 90)));
              if (Math.abs(_rotate) == 90 || Math.abs(_rotate) == 270) {
                setScale(0.65);
              } else {
                setScale(1);
              }
              if (_rotate == 360 || _rotate == -360) {
                setRotate(0);
              } else {
                setRotate(Math.min(360, Math.max(-360, Number(rotate - 90))));
              }
            }}
          />
          <button
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid",
              borderColor: style.common.borderColor,
              fontSize: 14,
              height: "44px",
              cursor: "pointer",
              outline: "none",
              margin: "7.5px 0px",
              color: "white",
              width: "100px",
              backgroundColor: style.common.t_color,
              borderRadius: 20,
            }}
            onClick={() => {
              if (setIsCrop) {
                apiPro_img(null);
              } else {
                setImageLoading(true);
                if (params["type"] == "d" || params["type"] == "rectangle") {
                  apiPro_img(null);
                } else if (
                  params["type"] == "set" ||
                  params["type"] == "se" ||
                  params["type"] == "qnaRegi"
                ) {
                  set_img(null);
                } else if (params.type === "searchImage" || params.type === "bookImageSearch") {
                  handleSearchImage();
                }
              }
            }}
          >
            {params["type"] == "se" ? "검색" : params["type"] == "qnaRegi" ? "등록" : "확인"}
          </button>
          {/* <UiBtn
                    title={params["type"] == "se" ? "검색" : "확인" }
                    type={"small"}
                    btnStyle={{
                        width : "100px",
                        height:"40px",
                        backgroundColor : style.common.t_color,
                        color : "white",
                        borderRadius : 20,
                    }}
                    fnClick={()=>{
                        if(params["type"] == "d"){
                        apiPro_img();
                        }
                        else if(params["type"] == "set" || params["type"] == "se" ) {
                        set_img();
                        }
                    }}
                /> */}
          <RotateRight
            style={{
              fontSize: "48px",
            }}
            onClick={() => {
              // setRotate(Math.min(180, Math.max(-180, Number(rotate+90))))
              let _rotate = Math.min(360, Math.max(-360, Number(rotate + 90)));
              if (Math.abs(_rotate) == 90 || Math.abs(_rotate) == 270) {
                setScale(0.65);
              } else {
                setScale(1);
              }
              if (_rotate == 360 || _rotate == -360) {
                setRotate(0);
              } else {
                setRotate(Math.min(360, Math.max(-360, Number(rotate + 90))));
              }
            }}
          />
        </div>
      </div>
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            display: "flex",
            // flex:1,
            width: "100vw",
            height: "100vh",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(200,200,200,0.5)",
          }}
        >
          <CircularProgress
            style={{
              color: style.common.t_color,
            }}
          />
          <div
            style={{
              marginTop: "15px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              fontWeight: 600,
              color: style.common.t_color,
            }}
          >
            {/* <div>
                        {text}
                    </div> */}
            <div>잠시만 기다려주세요</div>
          </div>
        </div>
      )}
      <div
        style={{
          display: "none",
        }}
      >
        {Boolean(completedCrop) && (
          <canvas
            ref={previewCanvasRef}
            style={{
              // display:"none",
              zIndex: -2,
              position: "absolute",
              border: "1px solid black",
              objectFit: "contain",
              // width: completedCrop.width,
              // height: completedCrop.height,
            }}
            width={351}
            height={468}
          />
        )}
      </div>
    </div>
  );
}
